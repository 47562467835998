$highlight-primary: #cccccc;
$highlight-secondary: #6699cc;
$highlight-background: #2d2d2d;
$highlight-lineno-background: #424242;
$highlight-selected: #515151;
$highlight-operator: #66cccc;
$highlight-keyword: #cc99cc;
$highlight-namespace: #ffcc66;
$highlight-comment: #999999;
$highlight-scoped: #f2777a;
$highlight-numeric: #f99157;
$highlight-string: #99cc99;

@import "../base"
