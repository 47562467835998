 .fa- {
   &gitee {
     &:before {
       font-family: mastodon-custom;
       content: "\e60c";
     }
   }

   &azure_oauth2 {
     &:before {
       font-family: Font Awesome\ 5 Brands;
       content: "\f3ca";
     }
   }
 }
